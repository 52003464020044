<template>
  <div class="relative flex flex-col justify-end">
    <!-- Toggle btn Start -->
    <button
      class="toggle-btn p-1.5 rounded-full duration-500 relative z-10"
      :class="
        isMenuOpen
          ? 'open bg-primary hover:bg-primary-600 text-white'
          : 'bg-slate-300 dark:bg-slate-700 hover:bg-primary-300 dark:hover:bg-slate-600/50'
      "
      @click="toggleMenuOpen()"
    >
      <div
        class="ellipsis h-7 w-7 relative flex flex-col justify-around items-center"
        :class="isMenuOpen ? 'open' : ''"
      >
        <span class="dot-1"></span>
        <span class="dot-1"></span>
        <span class="dot-1"></span>
      </div>
    </button>
    <!-- toggle btn end -->

    <div
      v-if="isMenuOpen"
      class="absolute bottom-0 sm:bottom-10 left-10 sm:left-1/2 sm:-translate-x-1/2 gap-x-4 sm:space-y-4 ml-4 sm:ml-0 sm:pb-7 flex items-center sm:block backdrop-blur rounded-full"
    >
      <!-- Language start -->
      <AppTooltip
        v-motion
        :position="isMobile ? 'left' : 'right'"
        :initial="isMobile ? { opacity: 0, x: 60 } : { opacity: 0, y: 60 }"
        :enter="isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }"
        :duration="150"
        :delay="0"
      >
        <template #trigger>
          <LanguagePicker
            toggle
            dropdown-direction="right"
            short-label
            class="text-xl text-slate-500 dark:text-slate-400 font-medium h-10 w-10 rounded-full grid place-content-center duration-200"
            :class="iconClass"
          />
        </template>
        <span class="text-xs whitespace-nowrap">{{ $t("changeLanguage") }}</span>
      </AppTooltip>
      <!-- Language end -->

      <!-- Theme start -->
      <AppTooltip
        v-motion
        :position="isMobile ? 'left' : 'right'"
        :initial="isMobile ? { opacity: 0, x: 45 } : { opacity: 0, y: 45 }"
        :enter="isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }"
        :duration="150"
        :delay="70"
      >
        <template #trigger>
          <ThemeToggle
            class="!ml-0 scale-125 origin-right sm:origin-center translate-x-1 rounded-full duration-200"
            :class="iconClass"
          />
        </template>
        <span class="text-xs whitespace-nowrap">{{
          $t("SwitchbetweenLightandDarkMode")
        }}</span>
      </AppTooltip>
      <!-- Theme end -->

      <!-- v2 feature btn start -->
      <V2ToggleButton
        v-motion
        class="!p-2.5"
        :initial="isMobile ? { opacity: 0, x: 30 } : { opacity: 0, y: 30 }"
        :enter="isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }"
        :delay="100"
        :duration="150"
      />
      <!-- v2 feature btn end -->

      <!-- Sign out start -->
      <AppTooltip
        v-motion
        :position="isMobile ? 'top' : 'right'"
        :initial="
          isMobile
            ? { opacity: 0, x: 15 }
            : { opacity: 0, y: 15, 'pointer-events': 'none' }
        "
        :enter="
          isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0, 'pointer-events': 'all' }
        "
        :duration="150"
        :delay="360"
        class="h-max"
      >
        <template #trigger>
          <button
            class="h-10 w-10 opacity-70 rounded-full relative duration-200 block"
            :class="iconClass"
            @click="emit('signOut')"
          >
            <PowerIcon
              class="h-6 w-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 stroke-2 text-slate-700 dark:text-slate-100"
            />
          </button>
        </template>
        <span class="text-xs whitespace-nowrap">{{ $t("auth.signOut") }}</span>
      </AppTooltip>
      <!-- Sign out end -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { PowerIcon } from "@heroicons/vue/24/outline";
import LanguagePicker from "../i18n/LanguagePicker.vue";
import AppTooltip from "../shared/AppTooltip.vue";
import ThemeToggle from "../shared/ThemeToggle.vue";
import V2ToggleButton from "./V2ToggleButton.vue";

const iconClass =
  "bg-slate-300 dark:bg-slate-700 hover:bg-primary-300 dark:hover:bg-slate-600 duration-200";

const { isMenuOpen, toggleMenuOpen } = useV2Menu();

const emit = defineEmits(["signOut"]);
</script>

<style scoped>
.toggle-btn .ellipsis span {
  display: block;
  height: 4px;
  width: 4px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  background-color: currentColor;
  transform-origin: center;
}

.toggle-btn:hover .ellipsis span:nth-of-type(1) {
  height: 8px;
  width: 8px;
}

.toggle-btn.open .ellipsis span:nth-of-type(1),
.toggle-btn.open .ellipsis span:nth-of-type(3) {
  width: 75%;
  height: 2px;
  /* position: absolute;
  top: 50%; */
}

.toggle-btn.open .ellipsis span:nth-of-type(1) {
  transform: rotate(45deg) translateY(8px) translateX(7px);
  /* background-color: red; */
  /* opacity: 0.3; */
}
.toggle-btn.open .ellipsis span:nth-of-type(2) {
  opacity: 0;
}
.toggle-btn.open .ellipsis span:nth-of-type(3) {
  transform: rotate(-45deg) translateY(-7px) translateX(6px);
  transition: all 0.3s ease-in-out;
  /* opacity: 0.3; */
}
</style>
