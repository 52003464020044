import type { ISubscriptionPlan } from "./types";
import { isAfter, format } from "date-fns";

export const useBillingStore = defineStore("billing", () => {
  const subscriptionInfo = ref<ISubscriptionPlan>();

  function setSubscription(response: ISubscriptionPlan) {
    subscriptionInfo.value = {
      ...response,
      creditsAvailable: +response.creditsAvailable || 0,
      creditsInUseTasksRunning: +response.creditsInUseTasksRunning || 0,
      creditsUsed: +response.creditsUsed || 0,
      freeTrialDaysLeft: +response.freeTrialDaysLeft || 0,
    };
  }

  const isFreeTrialActive = computed(() => {
    return subscriptionInfo.value?.priceId === "not_stripe_free_trial";
  });

  const freeTrialDaysLeft = computed(() => {
    return subscriptionInfo.value?.freeTrialDaysLeft;
  });

  const subscriptionCancelAt = computed(() => {
    return subscriptionInfo.value?.planCancelAt
      ? format(new Date(subscriptionInfo.value.planCancelAt * 1000), "do MMMM yyyy")
      : "";
  });

  const planStatus = computed(() => {
    return subscriptionInfo.value?.planStatus;
  });
  const freeTrialEnd = computed(() => {
    return subscriptionInfo.value?.freeTrialEnd;
  });

  const hasBypass = computed(() => !!subscriptionInfo.value?.planByPass);

  const hasCredits = computed(() => {
    return (
      subscriptionInfo.value &&
      subscriptionInfo.value?.creditsAvailable &&
      +subscriptionInfo.value?.creditsAvailable > 0
    );
  });

  const isWithinBillingPeriod = computed(() => {
    if (!subscriptionInfo.value) return false;
    if (!subscriptionInfo.value.billingPeriod) return false;
    const [month, year] = subscriptionInfo.value.billingPeriod.split("-");
    if (!month || !year) return false;
    const date = new Date();
    date.setMonth(parseInt(month) - 1);
    date.setFullYear(parseInt(year));
    date.setHours(0, 0, 0, 0);
    return isAfter(new Date(), date);
  });

  const hasFreeTrial = computed(() => {
    return (
      subscriptionInfo.value?.previousPriceIds?.length || subscriptionInfo.value?.priceId
    );
  });

  return {
    hasBypass,
    isFreeTrialActive,
    hasCredits,
    freeTrialEnd,
    setSubscription,
    subscriptionInfo,
    isWithinBillingPeriod,
    hasFreeTrial,
    freeTrialDaysLeft,
    subscriptionCancelAt,
    planStatus,
  };
});
