import Bugsnag from "@bugsnag/js";
import type { RouteLocationNormalized } from "vue-router";

const allowedRoutes = [
  "/init",
  "/error",
  "/plans",
  "/logout",
  "/verify",
  "/register",
  "/onboarding",
];

export function tenantMiddleware(to: RouteLocationNormalized) {
  const { user, isTenantWithActiveSubAccount } = useCurrentUser();
  const isTenantPath = to.path.startsWith("/tenant");
  const isAllowedRoute = allowedRoutes.includes(to.path);
  const isOnboardingRequired = currentSessionInformation.onboardingRequired;
  if (
    isOnboardingRequired &&
    user.value &&
    user.value.isPartOfTenantAccount &&
    !isTenantPath &&
    !isAllowedRoute
  ) {
    return "/onboarding";
  }
  if (isAllowedRoute) {
    return true;
  }
  if (
    !isTenantWithActiveSubAccount.value &&
    user.value?.isPartOfTenantAccount &&
    !isTenantPath
  ) {
    return "/tenant";
  }
  if (user.value?.isPartOfTenantAccount && !isTenantPath) {
    return true;
  }
  if (user.value && isTenantPath && !user.value.tenantId) {
    return "/tasks";
  }

  return true;
}
