<template>
  <div
    class="fixed bottom-0 sm:bottom-[unset] sm:top-0 w-full sm:w-16 md:w-24 shrink-0 grow-0 z-50 no-scrollbar border-t sm:border-r border-slate-200 dark:border-slate-700 dark:sm:border-slate-800 bg-slate-100 dark:bg-slate-800 sm:bg-transparent dark:sm:bg-transparent"
    :class="v2LayoutEnabled ? 'sm:h-full' : 'sm:h-screen'"
  >
    <div
      class="sm:h-full flex gap-4 sm:gap-0 flex-row-reverse sm:flex-col justify-between after:w-16 sm:after:w-auto sm:after:flex-1 after:mt-auto after:pointer-events-none w-full"
    >
      <div class="sm:flex-1 w-1 min-[420px]:w-14 sm:w-auto">
        <div
          v-if="router.currentRoute.value.fullPath !== '/'"
          class="flex justify-end sm:justify-center mr-6 sm:mr-0 items-center my-4"
          :class="isMobile ? 'opacity-0 pointer-events-none' : ''"
        >
          <DropdownProfile
            :avatar-url="user?.picture"
            @sign-out="doLogout"
            :align="isMobile ? 'right' : 'left'"
            class=""
            :hide-settings="!billingStore.hasBypass && !userSubscriptionPriceId"
          />
        </div>
      </div>
      <div
        class="flex-1 grow flex items-center"
        :class="{
          'opacity-0 pointer-events-none':
            !billingStore.hasBypass && !userSubscriptionPriceId,
        }"
      >
        <nav class="w-full" @click="maybeCloseV2Chat">
          <ul
            class="flex justify-center items-center sm:block space-x-4 min-[360px]:space-x-6 sm:space-y-4 sm:space-x-0 mx-auto sm:mx-0 duration-500"
            :class="
              isV2MenuOpen && isMobile ? 'opacity-0 pointer-events-none' : 'opacity-100'
            "
          >
            <template v-if="isInitializing">
              <li
                v-for="i of 5"
                :key="i"
                class="sm:py-1 h-full sm:h-[unset] flex items-center justify-center"
              >
                <Skeletor :circle="false" class="h-9 w-9 sm:h-8 sm:w-8 rounded-md" />
              </li>
            </template>
            <template v-else>
              <li class="sm:py-2 h-full sm:h-[unset]">
                <router-link
                  :to="{ name: 'suggestions' }"
                  custom
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isActive
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{ $t("suggestions") }}</span>
                    <div class="relative">
                      <span
                        v-if="hasSuggestions"
                        class="absolute inline-block -right-2 -top-1 bg-yellow-500 text-white px-2 py-1.5 rounded-full text-xs"
                      >
                        <!-- {{ suggestionCount }} -->
                        <span class="block h-1 w-1 bg-white rounded-full"></span>
                      </span>
                      <BulbIcon class="h-9 w-9 sm:h-8 sm:w-8" />
                    </div>
                  </a>
                </router-link>
              </li>
              <li class="sm:py-2 h-full sm:h-[unset] -mb-1.5 sm:mb-0">
                <router-link
                  :to="{ name: 'task-list', params: { status: 'recent' } }"
                  custom
                  v-slot="{ href, navigate }"
                >
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isTaskRoute
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{ $t("TaskList") }}</span>
                    <svg
                      class="fill-current h-9 w-8 sm:h-7 sm:w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 24"
                    >
                      <path fill-opacity=".16" fill-rule="nonzero" d="M1 5h16v14H1z" />
                      <path
                        fill-rule="nonzero"
                        d="M2 6v12h14V6H2Zm16-2v16H0V4h18ZM2 2V0h14v2H2Z"
                      />
                    </svg>
                  </a>
                </router-link>
              </li>
              <li class="sm:py-2 h-full sm:h-[unset]">
                <router-link
                  :to="{ name: 'scheduled-activities' }"
                  custom
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isActive
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{
                      $t("taskList.scheduledTaskRunsTitle")
                    }}</span>
                    <TimerIcon class="h-10 w-10 sm:h-8 sm:w-8" />
                  </a>
                </router-link>
              </li>
              <li class="sm:py-2 h-full sm:h-[unset]">
                <router-link
                  :to="{ name: 'brain' }"
                  custom
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isActive
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{ $t("myAssistant") }}</span>
                    <CpuIcon class="h-9 w-9 sm:h-8 sm:w-8" />
                  </a>
                </router-link>
              </li>
              <li
                v-if="hasFeatureFlag('messagesUi') && hasEmailIntegration"
                class="sm:py-2"
              >
                <router-link to="/messages" custom v-slot="{ href, navigate, isActive }">
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isActive
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{ $t("buttons.chat") }}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                      class="h-10 w-10 sm:h-8 sm:w-8"
                    >
                      <g fill="currentColor">
                        <path
                          d="M224 56v128a8 8 0 0 1-8 8h-59.47a8 8 0 0 0-6.86 3.88l-14.81 24.24a8 8 0 0 1-13.72 0l-14.81-24.24a8 8 0 0 0-6.86-3.88H40a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8h176a8 8 0 0 1 8 8"
                          opacity=".2"
                        />
                        <path
                          d="M216 40H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h59.47l14.81 24.23a16 16 0 0 0 27.41.06L156.53 200H216a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16m0 144h-59.47a16.07 16.07 0 0 0-13.69 7.71L128 216l-14.85-24.3a16.08 16.08 0 0 0-13.68-7.7H40V56h176Z"
                        />
                      </g>
                    </svg>
                  </a>
                </router-link>
              </li>
              <li class="sm:py-2">
                <router-link to="/settings" custom v-slot="{ href, navigate, isActive }">
                  <a
                    class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
                    :class="
                      isActive
                        ? 'text-sky-500 after:bg-sky-500'
                        : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span class="sr-only">{{ $t("settings.settings") }}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-10 w-10 sm:h-8 sm:w-8"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M5 19h14V5H5v14zm2.5-7c0-.2.02-.39.04-.58l-1.27-.99a.306.306 0 0 1-.07-.39l1.2-2.07c.08-.13.23-.18.37-.13l1.49.6c.31-.25.66-.44 1.02-.6l.22-1.59a.31.31 0 0 1 .3-.25h2.4c.15 0 .27.11.3.25l.22 1.59c.37.15.7.35 1.01.59l1.49-.6c.14-.05.29 0 .37.13l1.2 2.07c.08.13.04.29-.07.39l-1.27.99c.03.2.04.39.04.59c0 .2-.02.39-.04.58l1.27.99c.11.09.15.26.07.39l-1.2 2.07c-.08.13-.23.18-.37.13l-1.49-.6c-.31.24-.65.44-1.01.59l-.22 1.59a.31.31 0 0 1-.3.26h-2.4a.31.31 0 0 1-.3-.25l-.22-1.59c-.37-.15-.7-.35-1.01-.59l-1.49.6c-.14.05-.29 0-.37-.13l-1.2-2.07c-.08-.13-.04-.29.07-.39l1.27-.99c-.03-.2-.05-.39-.05-.59z"
                        opacity=".3"
                      />
                      <path
                        fill="currentColor"
                        d="m6.21 13.97l1.2 2.07c.08.13.23.18.37.13l1.49-.6c.31.24.64.44 1.01.59l.22 1.59c.03.14.15.25.3.25h2.4c.15 0 .27-.11.3-.26l.22-1.59c.36-.15.7-.35 1.01-.59l1.49.6c.14.05.29 0 .37-.13l1.2-2.07c.08-.13.04-.29-.07-.39l-1.27-.99c.03-.19.04-.39.04-.58c0-.2-.02-.39-.04-.59l1.27-.99c.11-.09.15-.26.07-.39l-1.2-2.07a.306.306 0 0 0-.37-.13l-1.49.6c-.31-.24-.64-.44-1.01-.59l-.22-1.59a.31.31 0 0 0-.3-.25h-2.4a.31.31 0 0 0-.3.26l-.22 1.59c-.36.15-.71.34-1.01.58l-1.49-.6c-.14-.05-.29 0-.37.13l-1.2 2.07c-.08.13-.04.29.07.39l1.27.99c-.03.2-.05.39-.05.59c0 .2.02.39.04.59l-1.27.99c-.11.1-.14.26-.06.39zM12 10.29c.94 0 1.71.77 1.71 1.71s-.77 1.71-1.71 1.71s-1.71-.77-1.71-1.71s.77-1.71 1.71-1.71zM19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 16H5V5h14v14z"
                      />
                    </svg>
                  </a>
                </router-link>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TimerIcon from "@/components/icons/TimerIcon.vue";
import DropdownProfile from "./DropdownProfile.vue";
import CpuIcon from "@/components/icons/CpuIcon.vue";
import BulbIcon from "@/components/icons/BulbIcon.vue";

import { Skeletor } from "vue-skeletor";
const { user, hasIntegration } = useCurrentUser();
const router = useRouter();
const billingStore = useBillingStore();
const { userSubscriptionPriceId } = toRefs(currentSessionInformation);
const v2ResponseStore = useV2ResponseStore();
const { isMenuOpen: isV2MenuOpen } = useV2Menu();

async function doLogout() {
  await router.push("/logout");
}

const { isInitializing } = useAppInit();
const suggestionsRequestShouldWait = computed(() => !user.value);
const { data: suggestions } = useAssistantSuggestionsRequest(
  ref(""),
  suggestionsRequestShouldWait
);

const hasEmailIntegration = computed(() => hasIntegration("email"));

const currentRoute = computed(() => router.currentRoute.value);
const { v2LayoutEnabled } = useV2Layout();
const isTaskRoute = computed(() => currentRoute.value.path.startsWith("/task"));
const hasSuggestions = computed(() => !!suggestions.value?.length);

function maybeCloseV2Chat() {
  if (!v2LayoutEnabled.value) return;
  v2ResponseStore.hideResponseOverlay();
}
</script>
