<template>
  <div
    class="grow relative"
    :class="{
      'h-[calc(100vh-3rem)] sm:h-[calc(100vh-3.5rem)]': v2LayoutEnabled && isTenant,
      'h-screen': v2LayoutEnabled && !isTenant,
      'min-h-screen': !v2LayoutEnabled,
      'grid grid-rows-[1fr_auto_60px] sm:grid-rows-[1fr_auto]':
        v2LayoutEnabled && !isOnboarding,
      'grid grid-rows-[1fr_auto_auto]': v2LayoutEnabled && isOnboarding,
    }"
  >
    <div id="main-app-content" class="h-full grow grid grid-rows-[1fr_auto] grid-cols-1">
      <div
        id="main-app-content"
        class="h-full grow flex flex-col relative"
        :class="v2LayoutEnabled ? 'overflow-y-auto' : ''"
      >
        <div class="absolute inset-0">
          <slot :isV2Ready="isV2Ready" />
        </div>
      </div>

      <!-- collapsed result overlay -->
      <div
        v-if="
          v2LayoutEnabled &&
          !v2InputStore.state.hidden &&
          v2InputStore.state.key === 'DEFAULT'
        "
        class=""
      >
        <CollapsedConversation />
      </div>
      <!-- collapsed result overlay end -->

      <!-- v2 heading -->
      <div id="v2-sub-header" class="flex flex-col relative"></div>
      <!-- v2 heading end -->
    </div>

    <!-- response overlay (chat) docked to right edge -->
    <div
      v-if="isV2Ready && v2LayoutEnabled && !v2ResponseStore.isResponseOverlayCollapsed"
      class="fixed right-0 top-0 z-50 w-auto"
      :style="`bottom: ${bottomSectionClearance}px`"
    >
      <ResizableWrapper
        height="100%"
        :width="v2ResponseStore.responseOverlayWidth"
        :active="['l']"
        :max-width="isMobile ? w.innerWidth : v2ResponseStore.responseOverlayMaxWidth"
        :min-width="isMobile ? w.innerWidth : v2ResponseStore.responseOverlayMinWidth"
        @resize:end="setOverlayWidth"
      >
        <V2ResultOverlay
          class="w-full h-full"
          data-html2canvas-ignore
          :class="{
            'top-[unset] left-[unset] right-0 mb-16':
              v2ResponseStore.isResponseOverlayCollapsed,
          }"
        />
      </ResizableWrapper>
    </div>

    <!-- resizable v2 bottom section -->
    <div ref="v2FocusWrapper">
      <AppBottomSection
        v-if="isV2Ready && v2LayoutEnabled && isAuth && (!isFullscreen || isOnboarding)"
      />
    </div>

    <!-- V2 menu -->
    <teleport to="body">
      <div
        ref="menuFocusWrapper"
        class="flex sm:flex-col gap-2 fixed items-center left-4 sm:left-0 sm:w-[63px] md:w-[95px] bottom-2 sm:bottom-6 z-[55]"
        :class="v2LayoutEnabled ? '' : 'sm:hidden'"
      >
        <V2MenuToggles @sign-out="router.push({ name: 'logout' })" />
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import V2MenuToggles from "@/components/v2/V2MenuToggles.vue";
import V2ResultOverlay from "@/components/v2/V2ResultOverlay.vue";
import CollapsedConversation from "@/components/v2/conversation/CollapsedConversation.vue";
import ResizableWrapper from "@/components/wrapper/ResizableWrapper.vue";
// @ts-ignore
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";

const AppBottomSection = defineAsyncComponent(
  () => import("@/components/wrapper/AppBottomSection.vue")
);

defineProps<{
  isAuth: boolean;
  isFullscreen?: boolean;
  isOnTenantRoute?: boolean;
  isTenant?: boolean;
}>();

const v2FocusWrapper = ref<HTMLDivElement>();
const menuFocusWrapper = ref<HTMLDivElement>();
const { v2LayoutEnabled } = useV2Layout();
const v2ResponseStore = useV2ResponseStore();
const router = useRouter();
const isV2Ready = ref(false);
const v2InputStore = useV2InputStore();
const w = window;

const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(
  [v2FocusWrapper, menuFocusWrapper],
  {
    allowOutsideClick: true,
  }
);

const isOnboarding = computed(() => router.currentRoute.value.name === "onboarding");

const bottomSectionClearance = computed(() => {
  return (v2InputStore.bottomSectionHeight || 0) + (isMobile.value ? 66 : 7);
});

const activeConversationId = computed(
  () => router.currentRoute.value.query.activeConversationId as string
);

function setOverlayWidth(data: { height: number | string; width: number | string }) {
  if (isMobile.value || typeof data.width === "string") return;

  v2ResponseStore.responseOverlayWidth = data.width;
}

router.afterEach(to => {
  if (!v2LayoutEnabled.value || to.meta.disableScrollToBottom) return;
  nextTick(scrollV2UiToBottom);
});

watchEffect(() => {
  if (
    isOnboarding.value &&
    v2LayoutEnabled.value &&
    v2FocusWrapper.value &&
    menuFocusWrapper.value &&
    !v2InputStore.state.hidden
  ) {
    nextTick(() => {
      activateFocusTrap();
    });
  } else {
    deactivateFocusTrap();
  }
});

onMounted(() => {
  if (activeConversationId.value) {
    v2ResponseStore.showResponseOverlay();
  }
  nextTick(() => {
    isV2Ready.value = true;
  });
});
</script>

<!-- <style>
.image-tool__image {
  height: 400px;
  width: 400px;
}
</style> -->
