export async function handlePwaUpdates() {
  const { registerSW } = await import("virtual:pwa-register");

  console.log("pwa updates");
  const { onConfirm, reveal } = usePwaDialogHandler();

  const updateSW = registerSW({
    onNeedRefresh() {
      reveal();
      onConfirm(() => {
        updateSW(true);
      });
    },
    async onOfflineReady() {
      // maybe notify when install is complete
    },
  });
}
