import type { AssistantAccountSummaryData } from "@/types/assistant";

export function getAssistantResourceSummaryQueryKey() {
  return ["assistant-data", "account", "summary"];
}

export function useAssistantAccountSummaryRequest() {
  const query = useQuery({
    queryKey: getAssistantResourceSummaryQueryKey(),
    queryFn: async () => {
      const { data } = await useGetRequest<AssistantAccountSummaryData>(
        "/account/data/summary"
      );
      return data;
    },
    staleTime: 5 * MINUTE,
  });

  return query;
}
