import { useI18n } from "vue3-i18n";

export function useBillingBanners() {
  const { addBanner, removeBanner } = useAppBanner();
  const billingStore = useBillingStore();
  const i18n = useI18n();

  const FREE_PLAN_ACTIVE_BANNER_ID = 111111;
  const LIMIT_REACHED_BANNER_ID = 222222;
  const FREE_PLAN_EXPIRED_BANNER_ID = 333333;
  const SUBSCRIPTION_CANCELLATION_PENDING_ID = 444444;

  function addFreePlanActiveBanner() {
    const daysLeftString =
      billingStore.freeTrialDaysLeft === 1
        ? i18n.t("freeTrialDaysLeft", { daysLeft: billingStore.freeTrialDaysLeft })
        : i18n.t("freeTrialDaysLeftPlural", { daysLeft: billingStore.freeTrialDaysLeft });
    addBanner({
      id: FREE_PLAN_ACTIVE_BANNER_ID,
      title: `${i18n.t("billing.freePlanTitle")} - ${daysLeftString}`,
      message: i18n.t("billing.freePlanMessage"),
      dismissible: false,
      link: "/plans",
      linkText: i18n.t("words.Upgrade"),
    });
  }

  function addLimitReachedBanner() {
    const credits = billingStore.subscriptionInfo?.creditsInUseTasksRunning || 0;
    const suffix =
      credits && credits >= 0
        ? i18n.t("billingExceededCreditsAvailable", {
            creditAvailable: credits,
          })
        : "";
    addBanner({
      id: LIMIT_REACHED_BANNER_ID,
      title: i18n.t("billing.exceededTitle"),
      type: "warning",
      message: i18n.t("billing.exceededMessage") + (suffix ? ` ${suffix}` : ""),
      dismissible: false,
      link: "/settings/usage",
      linkText: i18n.t("words.Upgrade"),
    });
  }

  function addFreePlanExpiredBanner() {
    let freeTrialEndDate = billingStore.freeTrialEnd as string;
    freeTrialEndDate = formatDateAndTime(freeTrialEndDate, "MMMM do, yyyy");
    const bannerTitle =
      billingStore?.freeTrialDaysLeft === 0
        ? i18n.t("billing.freeTrialTitle")
        : i18n.t("billing.exceededTitle");

    const bannerMessage =
      billingStore.freeTrialDaysLeft === 0
        ? i18n.t("billing.trialMessage", { endDate: `${freeTrialEndDate}` })
        : i18n.t("billing.freeTrialExceededMessage");
    addBanner({
      id: FREE_PLAN_EXPIRED_BANNER_ID,
      title: bannerTitle,
      type: "error",
      message: bannerMessage,
      dismissible: false,
      link: "/plans",
      linkText: i18n.t("words.Upgrade"),
    });
  }

  function addSubscriptionCancellationPendingBanner() {
    addBanner({
      id: SUBSCRIPTION_CANCELLATION_PENDING_ID,
      title: i18n.t("cancellationPendingBannerTitle"),
      type: "warning",
      message: i18n.t("cancellationPendingBannerDescription", {
        cancelAtDate: billingStore.subscriptionCancelAt,
      }),
      dismissible: false,
      link: "/settings/usage",
      linkText: i18n.t("renew"),
    });
  }

  function maybeShowBanners() {
    if (billingStore.hasBypass) return;
    removeBillingBanners();
    if (billingStore.isFreeTrialActive) addFreePlanActiveBanner();
    if (billingStore.planStatus === "cancellationPending") {
      addSubscriptionCancellationPendingBanner();
    }
    if (
      billingStore.isFreeTrialActive &&
      (!billingStore.hasCredits ||
        billingStore.subscriptionInfo?.notStripeFreeTrialExpired)
    ) {
      removeFreePlanActiveBanner();
      addFreePlanExpiredBanner();
    } else if (billingStore.subscriptionInfo?.planId && !billingStore.hasCredits) {
      removeBillingBanners();
      addLimitReachedBanner();
    }
  }

  function removeFreePlanActiveBanner() {
    removeBanner(FREE_PLAN_ACTIVE_BANNER_ID);
  }

  function removeLimitExceededBanner() {
    removeBanner(LIMIT_REACHED_BANNER_ID);
  }

  function removeFreePlanExpiredBanner() {
    removeBanner(FREE_PLAN_EXPIRED_BANNER_ID);
  }

  function removeSubscriptionCancellationPendingBanner() {
    removeBanner(SUBSCRIPTION_CANCELLATION_PENDING_ID);
  }

  function removeBillingBanners() {
    removeFreePlanActiveBanner();
    removeFreePlanExpiredBanner();
    removeLimitExceededBanner();
    removeSubscriptionCancellationPendingBanner();
  }

  return {
    addFreePlanActiveBanner,
    addLimitReachedBanner,
    maybeShowBanners,
    removeBillingBanners,
    addFreePlanExpiredBanner,
    addSubscriptionCancellationPendingBanner,
    removeLimitExceededBanner,
    removeFreePlanActiveBanner,
    removeFreePlanExpiredBanner,
    removeSubscriptionCancellationPendingBanner,
  };
}
