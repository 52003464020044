<template>
  <button
    v-show="hasV2Feature"
    @click="toggleV2Layout()"
    data-testid="MM-toggleV2Button"
    :class="
      v2LayoutEnabled
        ? 'text-primary hover:text-primary-700'
        : 'hover:text-primary dark:hover:text-primary text-slate-500 dark:text-slate-300'
    "
    class="p-2 rounded-full opacity-80 bg-slate-100 dark:bg-slate-100/10 duration-200 flex-shrink-0 h-max"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5">
      <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.4"
      >
        <rect width="7" height="18" x="3" y="3" rx="1" />
        <rect width="7" height="7" x="14" y="3" rx="1" />
        <rect width="7" height="7" x="14" y="14" rx="1" />
      </g>
    </svg>
  </button>
</template>

<script setup lang="ts">
const { v2LayoutEnabled, toggleV2Layout, hasV2Feature } = useV2Layout();
</script>

<style scoped></style>
