import type { AnyFn } from "@vueuse/core";
import type { V2SectionInput } from "./types";
import type { ScheduleTime } from "@/types/task";
import { isPast } from "date-fns";

interface BottomSectionInputState {
  key: "DEFAULT" | "teleport" | string;
  inputs: V2SectionInput[];
  buttonLabel: string;
  isValid: boolean;
  buttonType: "save" | "send";
  done?: boolean;
  errors?: string[];
  saving: boolean;
  hidden?: boolean;
  scheduling?: boolean;
  scheduleData?: ScheduleTime;
  inputValues: Record<string, any>;
  skipStep?: boolean;
  onSubmitFn: AnyFn;
}

export const DEFAULT_V2_SUBMIT_EVENT = "v2InputSubmit";
export const SKIP_COMPETITOR_STEP = "skipCompetitorStep";
export const BACK_STEP_COMPETITOR_STEP = "backStep";
const event = new Event(DEFAULT_V2_SUBMIT_EVENT);

const skipEvent = new CustomEvent(SKIP_COMPETITOR_STEP, {
  detail: { skipStep: true },
});
const backEvent = new CustomEvent(BACK_STEP_COMPETITOR_STEP, {
  detail: { back: true },
});

function defaultSubmitHandler() {
  if (typeof document === "undefined") return;
  document.dispatchEvent(event);
}

function skipCompetitorStep() {
  if (typeof document === "undefined") return;
  document.dispatchEvent(skipEvent);
}

function backToStep() {
  if (typeof document === "undefined") return;
  document.dispatchEvent(backEvent);
}

const defaultState = (): BottomSectionInputState => ({
  key: "DEFAULT",
  buttonLabel: "Send",
  buttonType: "send",
  inputs: [],
  isValid: true,
  errors: [],
  done: false,
  hidden: false,
  saving: false,
  scheduling: false,
  inputValues: {},
  skipStep: false,
  onSubmitFn: defaultSubmitHandler,
});

export const defaultV2Height = 256;
export const defaultV2MaxHeight = Math.min(window.innerHeight / 2, 500);
export const defaultV2MinHeight = 155;

export const useV2InputStore = defineStore("v2InputStore", () => {
  const stateQueue = ref<BottomSectionInputState[]>([]);
  // mutated in store
  const state = ref<BottomSectionInputState>(defaultState());
  const bottomSectionHeight = useLocalStorage("bottomSectionHeight", defaultV2Height);
  const bottomSectionMinHeight = ref(defaultV2MinHeight);
  const bottomSectionMaxHeight = ref(defaultV2MaxHeight);
  const showBackButton = ref(false);
  // expose read-only value
  const inputs = computed(() => state.value.inputs);
  const hasInputs = computed(() => inputs.value.length > 0);

  const isCompetitorStep = computed(() => {
    return inputs.value.some(input =>
      input.namespace?.includes("onboarding/competitor-websites")
    );
  });

  const hasInvalidSChedule = computed(
    () =>
      state.value.scheduleData &&
      state.value.scheduleData.type === "date" &&
      isPast(new Date(`${state.value.scheduleData.value}.000Z`))
  );

  function toggleBottomSectionHeight(val?: number) {
    if (typeof val === "number") {
      bottomSectionHeight.value = val;
      if (val === 0) {
        bottomSectionMaxHeight.value = 1;
        bottomSectionMinHeight.value = 0;
      }
      return;
    }
    if (bottomSectionHeight.value < defaultV2Height) {
      bottomSectionHeight.value = defaultV2Height;
      bottomSectionMaxHeight.value = defaultV2MaxHeight;
      bottomSectionMinHeight.value = defaultV2MinHeight;
    } else {
      bottomSectionHeight.value = 0;
      bottomSectionMaxHeight.value = 1;
      bottomSectionMinHeight.value = 0;
    }
  }
  function enqueueInputState(s: BottomSectionInputState) {
    if (!s) return;
    if (!stateQueue.value.length && state.value.key === "DEFAULT") {
      stateQueue.value.push(s);
      dequeueInputState();
    } else {
      if (
        state.value?.key === s?.key &&
        state.value?.inputs?.every(oldInput =>
          s.inputs?.some(newInput => newInput?.key === oldInput?.key)
        )
      ) {
        state.value = Object.assign({}, s, { inputValues: state.value.inputValues });
      }
      stateQueue.value.push(s);
    }
  }

  function dequeueInputState() {
    if (!stateQueue.value.length) {
      setDefaultState();
      return;
    }
    const newState = stateQueue.value.pop();
    if (newState) state.value = newState;
  }

  function setDefaultState() {
    state.value = defaultState();
  }

  const currentStateHelpers = {
    setValidation(isValid: boolean) {
      state.value.isValid = isValid;
    },
    setButtonState(btn: {
      label: string;
      buttonType: BottomSectionInputState["buttonType"];
    }) {
      state.value.buttonLabel = btn.label;
      state.value.buttonType = btn.buttonType;
    },
    setSubmitFunction(fn: AnyFn) {
      state.value.onSubmitFn = fn;
    },
    setErrors(errors: string[]) {
      state.value.errors = errors;
    },
    addError(e: string) {
      state.value.errors = [...(state.value.errors || []), e];
    },
    dismissActiveError() {
      if (!state.value.errors?.length) return;
      if (state.value.errors.length === 1) {
        state.value.errors = [];
        return;
      }
      state.value.errors = state.value.errors.slice(1);
    },
    setDone() {
      state.value.done = true;
      state.value.saving = false;
      setTimeout(() => {
        state.value.done = false;
      }, 1.2 * SECOND);
    },
    setInputs(inputs: V2SectionInput[]) {
      if (inputs && inputs.length) state.value.inputs = inputs;
    },
    saveStep() {
      if (!state.value.onSubmitFn) return;
      state.value.onSubmitFn();
      if (state.value.key !== "DEFAULT") defaultSubmitHandler(); // emit event
    },
    skipStep() {
      showBackButton.value = true;
      skipCompetitorStep();
    },
    backToCompetitorStep() {
      backToStep();
    },
    setLoading() {
      state.value.done = false;
      state.value.saving = true;
    },
    hideInputs() {
      state.value.hidden = true;
    },
    showInputs() {
      state.value.hidden = false;
    },
    toggleScheduling(value?: boolean) {
      if (value === undefined) {
        state.value.scheduling = !state.value.scheduling;
        return;
      }
      state.value.scheduling = value;
    },
  };

  return {
    state,
    inputs,
    hasInputs,
    isCompetitorStep,
    showBackButton,
    hasInvalidSChedule,
    toggleBottomSectionHeight,
    bottomSectionHeight,
    bottomSectionMaxHeight,
    bottomSectionMinHeight,
    dequeueInputState,
    enqueueInputState,
    setDefaultState,
    currentStateHelpers,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useV2InputStore, import.meta.hot));
}
