import { helpData, type HelpDataBlock, type HelpDataKey } from "./data";

export const useAppHelpStore = defineStore("appHelp", () => {
  // const { data, isFetching, error } = useFetch("/help.json");
  const helpSidebarOpen = ref(false);
  const inlineHelpOpen = ref(false);
  const currentContentKey = ref<keyof typeof helpData>("none");
  const currentContent = computed(() => {
    const key = currentContentKey.value || "none";
    return helpData[key] as HelpDataBlock[];
  });
  const history = reactive(new Stack<HelpDataKey>());

  const hasHistory = computed(() => {
    return !history.isEmpty;
  });

  function setSidebarContent(key: HelpDataKey) {
    const isInlineHelp = checkIsInlineHelp(key);
    if (
      !isInlineHelp &&
      currentContentKey.value !== "none" &&
      history.peek() !== currentContentKey.value
    ) {
      history.push(currentContentKey.value);
    }
    currentContentKey.value = key;
  }

  function toggleHelpSidebar(val?: boolean) {
    if (typeof val === "boolean") {
      if (!val) currentContentKey.value = "none";
      helpSidebarOpen.value = val;
    } else {
      helpSidebarOpen.value = !helpSidebarOpen.value;
    }
  }

  function toggleInlineHelp(val?: boolean) {
    if (typeof val === "boolean") {
      inlineHelpOpen.value = val;
    } else {
      inlineHelpOpen.value = !inlineHelpOpen.value;
    }
  }

  function openHelpSidebar(key: HelpDataKey) {
    toggleHelpSidebar(true);
    if (currentContentKey.value === key) return;
    setSidebarContent(key);
  }

  function openInlineHelp(key: HelpDataKey) {
    toggleInlineHelp(true);
    if (currentContentKey.value === key) return;
    setSidebarContent(key);
  }

  function goToPreviousTopic() {
    if (history.isEmpty) return;
    const key = history.pop();
    if (key) currentContentKey.value = key;
  }

  const feedbackAlreadySubmittedFor = ref<string[]>([]);

  function shouldShowFeedbackLine(key: string) {
    if (feedbackAlreadySubmittedFor.value.indexOf(key) > -1) {
      return false;
    }
    return true;
  }

  function checkIsInlineHelp(key: HelpDataKey) {
    return typeof key === "string" && key.startsWith("onboarding/");
  }

  return {
    shouldShowFeedbackLine,
    setSidebarContent,
    currentContentKey,
    helpSidebarOpen,
    inlineHelpOpen,
    hasHistory,
    openInlineHelp,
    goToPreviousTopic,
    toggleHelpSidebar,
    openHelpSidebar,
    toggleInlineHelp,
    currentContent,
  };
});

acceptHMRUpdate(useAppHelpStore, import.meta.hot);
